import { HttpClient, HttpClientBlob } from "./HttpClient";
import { AxiosResponse } from "axios";

export class BlogService {
  static async GetBlogs(lang: string): Promise<AxiosResponse<any>> {
    return HttpClient.get(`/blog?lang=${lang}`);
  }

  static async GetBlog(lang: string, url: string): Promise<AxiosResponse<any>> {
    return HttpClient.get(`/blog?lang=${lang}&url=${url}`);
  }
}
