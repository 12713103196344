import { render, staticRenderFns } from "./BlogDynamic.vue?vue&type=template&id=86e6f588&scoped=true"
import script from "./BlogDynamic.vue?vue&type=script&lang=ts"
export * from "./BlogDynamic.vue?vue&type=script&lang=ts"
import style0 from "./BlogDynamic.vue?vue&type=style&index=0&id=86e6f588&prod&scoped=true&lang=css"
import style1 from "@/assets/css/dealfinder.css?vue&type=style&index=1&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86e6f588",
  null
  
)

export default component.exports