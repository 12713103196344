
import { Vue, Component } from "vue-property-decorator";
import { BlogService } from "@/api/BlogServiceApi";
import { IBlogEntryView } from "@/models/BlogEntry";
import { getS3BasePath } from "@/utility/StaticFileHelper";
import { GetStartingLocale } from "@/utility/LanguageHelper";

@Component({})
export default class BlogDynamic extends Vue {
  blogEntry: IBlogEntryView = {
    author: "",
    blogUrl: "",
    createdDate: "",
    pictureUrl: "",
    quotationCategoryId: 0,
    text: "",
    title: ""
  };

  async created() {
    let blogUrl = this.$route.params.blogUrl;
    let lang = this.$route.params.lang;

    if (blogUrl == undefined) {
      await this.$router.push("/blog/" + GetStartingLocale());
    }
    await BlogService.GetBlog(lang, blogUrl)
      .then(response => {
        this.blogEntry = response.data;
      })
      .catch(() => {
        this.$router.push("/blog/" + GetStartingLocale());
      });
  }

  fullPicturePath(pictureUrl: string) {
    return getS3BasePath() + pictureUrl;
  }
}
